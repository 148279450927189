<template>
  <div class="space-y-4">
    <div class="px-4 rounded-xl bg-white">
      <h1>My Tools</h1>
    </div>

    <div class="px-4 py-2 rounded-xl bg-white space-y-2">
      <h4>Wallet Login</h4>
      <div class="flex items-center">
        <button
          v-if="!is_login"
          class="px-4 py-2 rounded-xl bg-indigo-600 text-white"
          @click="onLogin()"
        >
          <div>Login</div>
        </button>
        <div v-else>{{ login_addr }}</div>
      </div>
    </div>

    <div v-if="is_login" class="px-4 py-2 rounded-xl bg-white space-y-2">
      <h4>Functions</h4>
      <div class="flex space-x-2">
        <button
          class="flex items-center px-4 pt-1 rounded-xl bg-indigo-600 text-white"
          @click="onGetNFTs()"
        >
          balanceOf()
        </button>
        <div class="" v-for="data in nft_uids" :key="data">
          {{ data }}
        </div>
      </div>
      <div class="pt-4 flex">
        <div>UID</div>
        <div>
          <input
            v-model="input_transfer_uid"
            class="border-b-2 border-black"
            type="text"
          />
        </div>
      </div>
      <div class="flex">
        <div>TO ADDR</div>
        <div>
          <input
            v-model="input_transfer_addr"
            class="border-b-2 border-black"
            type="text"
          />
        </div>
      </div>
      <button
        @click="onTransfer()"
        class="flex items-center px-4 pt-1 rounded-xl bg-indigo-600 text-white"
      >
        transferFrom()
      </button>
    </div>

    <div v-if="is_login" class="px-4 py-2 rounded-xl bg-white space-y-2">
      <h4>Settings</h4>
      <div class="flex space-x-4">
        <button
          @click="onReload(true)"
          class="flex items-center px-4 pt-1 rounded-xl bg-indigo-600 text-white"
        >
          View
        </button>
        <button
          @click="onReload(false)"
          class="flex items-center px-4 pt-1 rounded-xl bg-indigo-600 text-white"
        >
          Reload
        </button>
      </div>
      <div class="" v-html="settings_text"></div>
    </div>
  </div>
</template>

<script>
// import ContractMain from "@/abi/PlantosyDak-main-v83.json";
import ContractMain from "@/abi/PlantosyDakV3.json";
import Caver from "caver-js";

export default {
  data() {
    return {
      is_login: false,
      login_addr: null,
      contract: null,
      //
      input_transfer_uid: "",
      input_transfer_addr: "",
      //
      nft_uids: [],
      //
      settings_text: "",
    };
  },
  methods: {
    getContract() {
      if (this.contract === null) {
        const klaytn = window.klaytn;

        let networkId = 8217;
        if (process.env.VUE_APP_MODE != "PUBLIC") {
          networkId = 1001;
        }

        if (klaytn.networkVersion !== networkId) {
          this.$store.Alert("#ERR_CONTRACT_NETWORK_ID");
          return null;
        }

        if (ContractMain.networks[networkId] === undefined) {
          this.$store.Alert("#ERR_CONTRACT_NETWORK_ID");
          return null;
        }

        const caver = new Caver(klaytn);
        this.contract = new caver.klay.Contract(
          ContractMain.abi,
          ContractMain.networks[networkId].address
        );
      }

      return this.contract;
    },
    async onLogin() {
      const klaytn = window.klaytn;
      const accounts = await klaytn.enable();

      if (!Array.isArray(accounts) || accounts.length < 1) {
        this.$store.Alert("#ERR_NEED_KAIKAS");
        return;
      }

      const addr = accounts[0];
      if (!this.$store.isAdminAddr(addr)) {
        this.$store.Alert("#ERR_REGISTERED_WALLET_ONLY");
        return;
      }

      this.is_login = true;
      this.login_addr = accounts[0];
    },
    // baobab-main: 0x65EfC5ce9D6aB663365ac5452f52168f227a5d93
    // baobab-sub: 0x1e0A33d97f7793035704F5B0d896c631fb47BbE4
    async onTransfer() {
      const contract = this.getContract();
      if (contract === null) return;

      console.log(
        "onTransfer",
        this.input_transfer_uid,
        this.input_transfer_addr
      );

      const GAS_LIMIT = "2000000";

      contract.methods
        .transferFrom(
          this.login_addr,
          this.input_transfer_addr,
          this.input_transfer_uid
        )
        .send({ from: this.login_addr, gas: GAS_LIMIT });
    },
    async onGetNFTs() {
      this.nft_uids = [];

      const contract = this.getContract();
      if (contract === null) return;

      const addr = this.login_addr;
      let count = await contract.methods.balanceOf(addr).call();
      count = Math.min(count, 20);

      const nft_uids = [];
      for (let i = 0; i < count; i++) {
        const text = await contract.methods
          .tokenOfOwnerByIndex(this.login_addr, i)
          .call();
        const uid = Number(text);
        if (isNaN(uid)) continue;
        nft_uids.push(uid);
      }
      nft_uids.sort();
      this.nft_uids = nft_uids;
    },
    async onReload(is_view = false) {
      this.settings_text = "";

      let url = `${this.$store.GET_URL}/settings/load`;
      if (is_view) {
        url = `${this.$store.GET_URL}/settings/view`;
      }
      const data = await this.$store.getAsync(url);
      if (data.code !== undefined && data.code != 100) {
        this.$store.AlertPost(data);
        return;
      }

      this.settingToText(data);
    },
    settingToText(data) {
      const keys = Object.keys(data);
      const values = [];
      keys.forEach((k) => {
        values.push(`${k}: ${data[k]}`);
      });
      const text = values.join("<br/>\n");

      this.settings_text = text;
    },
  },
};
</script>
